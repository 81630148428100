import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/radu/code/ebe/src/components/BookLayout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Recomandăm acest curs cu speranța că este o încurajare la o creștere semnificativă în asemănarea cu Isus Cristos.Pentru a ajuta alte femei în mod eficient, trebuie în primul rând să ne cercetăm propria viață înaintea lui Dumnezeu. Scriptura ne învață cine suntem noi cu adevărat în Cristos. Trebuie să ne însușim această învățătură, în timp ce analizăm realitățile experiențelor noastre trecute, prezente și viitoare în umblare cu El.`}</MDXTag>
      <MDXTag name="p" components={components}>{`În al doilea rând, acest curs ne va ajuta ca, din plinătatea propriei relații cu Tatăl, să ne dezvoltăm capacitatea de a ajuta persoanele din jur să-și privească viața prin prisma Bibliei. Acest curs nu este menit și nici nu este suficient de adecvat pentru a ne pregăti în vederea consilierii profesioniste. El nu poate răspunde tuturor întrebărilor și situațiilor noastre specifice, dar ne oferă un cadru solid și util pentru evaluarea lor. Este un punct de plecare. Scopul lui este de a încuraja creștinele evlavioase să ajute mai eficient femeile din jurul lor.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Cele mai mari bogății ale unei femei înțelepte sunt cunoașterea temeinică a caracterului lui Dumnezeu și a Scripturii, precum și o inimă sensibilă și ascultătoare de Cuvântul Lui.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    